<script>
import { defineComponent } from 'vue'
import modal from '/~/core/mdl'
import ui from '/~/core/ui'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import DefaultDrawerEPurchase from '/~/components/drawer/views/e-purchases/drawer-e-purchase.vue'
import { useLocalization } from '/~/composables/localization'
import DrawerActions from '/~/templates/australiapost/components/drawer/components/actions/drawer-actions.vue'
import EpurchaseTile from '/~/templates/australiapost/components/drawer/components/tiles/drawer-e-purchases-tile.vue'

export default defineComponent({
  components: {
    BaseAsidePage,
    DrawerActions,
    EpurchaseTile,
  },
  extends: DefaultDrawerEPurchase,
  setup() {
    const { translate } = useLocalization()

    return { translate }
  },
  computed: {
    actions() {
      return [
        {
          label: 'View Bunnings eGift Card',
          description: 'Access your eGift Card at any time',
          icon: 'menu/eye',
          hidden: !this.externalUrl || !this.isBunningsGiftcard,
          click: () => {
            if (ui.mobile) {
              this.showExternalModal = true
            } else {
              modal.show('purchase-external', {
                props: {
                  url: this.externalUrl,
                  type: 'giftcard',
                },
              })
            }
          },
        },
        {
          label: 'Edit balance manually',
          description: 'Update your remaining balance',
          icon: 'australiapost/pencil',
          size: 17,
          hidden: Boolean(this.isCinema) || !this.isBalanceAvailable,
          click: () => {
            this.showBalanceModal = true
          },
        },
        {
          label: 'Buy Again',
          description: 'Buy another gift card from this retailer',
          icon: 'australiapost/shopping-bag',
          size: 19,
          click: () => {
            this.buyAgain()
          },
        },
        {
          label: 'Print',
          description: 'Print a copy of your gift card',
          icon: 'menu/eye',
          hidden: !this.externalUrl || ui.mobile || this.isBunningsGiftcard,
          click: () => {
            if (ui.mobile) {
              this.showExternalModal = true
            } else {
              modal.show('purchase-external', {
                props: {
                  url: this.externalUrl,
                  type: this.isCinema ? 'voucher' : 'giftcard',
                },
              })
            }
          },
        },
        {
          label: 'Delete',
          description: 'Delete this from your ePurchases',
          icon: 'australiapost/trash',
          size: 19,
          type: 'danger',
          confirm: {
            progressText: 'Deleting...',
            submit: async () => {
              try {
                await this.archiveGiftCard(this.uuid)
                this.$emit('delete')
              } catch (error) {
                console.error(error)
              }
            },
          },
        },
        {
          label: 'Terms of use',
          description: `View Terms ${this.translate(
            'common.and'
          )} Conditions of use`,
          icon: 'plain/file-document-edit',
          size: 21,
          click: () => {
            this.showTerms()
          },
        },
      ]
    },
  },
})
</script>

<template>
  <base-aside-page :key="id" :title="title" class="flex flex-col">
    <epurchase-tile v-if="card" :card="card" details-view class="px-11" />

    <div
      v-if="loading || !card || !details"
      class="mt-[30px] flex w-full items-center justify-center"
    >
      <base-loader />
    </div>

    <template v-else>
      <div
        v-if="isCinema && url"
        class="mt-[15px] w-full space-y-2.5 rounded-lg bg-light p-2.5"
      >
        <div class="m-auto pt-6" style="width: 240px">
          <img :src="url" />
        </div>
      </div>

      <template v-if="isBarcodes">
        <div
          v-for="(item, index) in barcodes"
          :key="index"
          class="mt-[15px] w-full"
        >
          <barcode-unlocked
            class="min-h-20"
            :details="item"
            :options="{
              background: 'transparent',
              fontSize: 16,
              displayValue: false,
            }"
          />

          <div
            v-if="item.cardNumber"
            class="mt-[5px] w-full text-center text-sm text-eonx-neutral-600"
          >
            {{ item.cardNumber }}
          </div>
          <div v-if="item.pin" class="mt-[15px] w-full text-center font-bold">
            PIN {{ item.pin }}
          </div>
        </div>
      </template>

      <drawer-actions :actions="actions" class="mt-[30px]" />

      <div class="mt-[30px] flex w-full flex-col items-center justify-center">
        <dl
          class="grid w-full grid-cols-3 gap-x-[5px] gap-y-2.5 rounded-lg bg-gray-50 p-5"
        >
          <template v-for="(item, index) in info">
            <dt
              :key="`dt${index}`"
              class="whitespace-nowrap leading-6 text-eonx-neutral-600"
            >
              {{ item.label }}
            </dt>
            <dd
              :key="`dd${index}`"
              class="col-span-2 break-words pl-[5px] text-right font-bold leading-6"
            >
              {{ item.value }}
            </dd>
          </template>
        </dl>
        <base-link v-if="!isCinema && url" :href="url" class="mt-5 w-full">
          <base-button full-width color="primary">Download</base-button>
        </base-link>
      </div>
    </template>

    <purchase-external-modal
      v-if="showExternalModal"
      :to="to"
      :visible.sync="showExternalModal"
      :url="externalUrl"
    />

    <purchase-balance-modal
      v-if="showBalanceModal"
      :to="to"
      :visible.sync="showBalanceModal"
    />
  </base-aside-page>
</template>
